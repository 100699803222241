import React from "react";
import Layout from "../components/Layout/Layout";
import {graphql} from "gatsby";
import SEO from "../components/Core/Seo";
import {Col, Row} from "styled-bootstrap-grid";
import Content from "../components/Documentation/Content";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";

const GridContainer = styled.div`
  margin-bottom: 150px;
  
  @media(min-width: ${breakpoints.md}){
    margin-top: 20px;
    margin-bottom: 150px;
  }
`;

const TextPage = ({location, data, pageContext}) => {
  const path = location.path;
  const page = data.page;

  return (
    <Layout>
      <SEO title={page.metaTitle}
           description={page.metaDescription}
           fullTitle={false}
           path={path}/>
      <GridContainer>
        <Row>
          <Col sm={12} md={6} mdOffset={3}>
            <Content title={page.title} content={page.content}/>
          </Col>
        </Row>
      </GridContainer>
    </Layout>
  )
};

export default TextPage;

export const pageQuery = graphql`
  query TextPageQuery($slug: String!) {
    page: contentfulTextPage(slug: {eq: $slug}) {
      id
      slug
      metaTitle
      metaDescription
      title
      content {
        raw
      }
    }
  }
`;