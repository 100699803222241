import React from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import colors from "../../styles/colors";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import {BLOCKS} from "@contentful/rich-text-types";
import Img from "gatsby-image";
// import {INLINES} from '@contentful/rich-text-types'

const ContentContainer = styled.div`
  p :first-of-type {
      border-bottom: 1px solid ${colors.greyDark};
  }
  
  iframe {
    width: calc(100vw - 32px);
    height: 50vw;
    max-height: 380px;
  }
  
  @media(min-width: ${breakpoints.md}){
    
    iframe {
      max-width: 100%;
      height: 419px;
      margin-top: 10px;
    }
  }
`;

const ImgStyled = styled(Img)`
    display: block;
    width: 100%;
    max-width: 267px;
    margin: 20px auto 40px auto;
    
    @media(min-width: ${breakpoints.md}){
        margin: 30px auto 40px auto;
    }
`;


const Content = ({title, content}) => {
  const assets = {};
  content.references && content.references.forEach((element) => {
    assets[element.contentful_id] = element
  });

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({data}) => {
        return (
          assets[data.target.sys.id] && <ImgStyled fluid={assets[data.target.sys.id].fluid}/>
        )
      },
    },
  };

  return (
    <ContentContainer>
      <h1>{title}</h1>
      {documentToReactComponents(JSON.parse(content.raw), options)}
    </ContentContainer>

  )
};

export default Content;